import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  PaidStaffedShifts,
  PaidStaffedShiftsVariables,
} from './__generated__/PaidStaffedShifts';

const PAID_STAFFED_SHIFTS = gql`
  query PaidStaffedShifts(
    $paginationOptions: PaginationOptions!
    $queryOptions: PayableStaffingQueryOptions!
  ) {
    paidStaffedShifts(
      paginationOptions: $paginationOptions
      queryOptions: $queryOptions
    ) {
      items {
        id
        updatedAt
        paymentStatus
        hasPendingPayment
        actualStartDateTime
        actualEndDateTime
        actualWorkedHours
        tendAdjustment
        tenderAdjustment
        actualTenderPayout
        actualUnpaidBreakMinutes
        invoicingStatus
        description
        staffing {
          id
          status
          tender {
            id
            firstName
            lastName
            email
            avatarURL
            phoneNumber
            address {
              city
              state
            }
          }
          shift {
            id
            startDateTime
            endDateTime
            unpaidBreakMinutes
            tenderHourlyPayout
            tip {
              type
              label
              amount
            }
            job {
              id
              name
              venue {
                address {
                  timezone
                }
              }
              client {
                id
                email
              }
            }
            position {
              name
            }
          }
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const usePaidStaffedShiftsQuery = (
  options?: QueryHookOptions<PaidStaffedShifts, PaidStaffedShiftsVariables>,
) =>
  useQuery<PaidStaffedShifts, PaidStaffedShiftsVariables>(
    PAID_STAFFED_SHIFTS,
    options,
  );

export default PAID_STAFFED_SHIFTS;
