import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Combobox from 'src/components/Combobox';
import Pill from 'src/components/Pill';
import { Regions_regions as RegionItem } from 'src/graphql/queries/__generated__/Regions';
import debounce from 'lodash.debounce';
import ChevronDown from '../Icons/ChevronDown';
import { useRegionsV2Query } from '../../graphql/queries/GetRegionsV2';

const RegionResult: FC<{
  item: RegionItem;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`region-result-${item.id}`}
    >
      <p className="text-ink-dark text-sm font-medium">{item.name}</p>
      <p className="text-ink-not-as-dark text-sm">{item.id}</p>
    </div>
  );
};

type Props = {
  selectedRegionsIds: string[];
  onChange: (selectedRegionsIds: string[]) => void;
  className?: string;
};

const RegionInput: FC<Props> = ({
  selectedRegionsIds,
  onChange,
  className,
}) => {
  const [getRegions] = useRegionsV2Query();
  const [regions, setRegions] = useState<RegionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState<RegionItem[]>([]);

  const getAllSelectedRegions = useCallback(async () => {
    const result = await getRegions();
    setRegions(result.data?.regions || []);
    setLoading(result.loading);
    if (!selectedRegionsIds || regions.length === 0) {
      return;
    }
    const filteredRegions = regions.filter((region) =>
      selectedRegionsIds.find((regionId) => region.id === regionId),
    );
    setSelectedRegions?.(filteredRegions);
  }, [
    regions,
    setRegions,
    setLoading,
    getRegions,
    selectedRegionsIds,
    setSelectedRegions,
  ]);

  useEffect(() => {
    getAllSelectedRegions();
  }, [getAllSelectedRegions]);

  const addRegion = (region: RegionItem) => {
    if (selectedRegions.some((selected) => selected.id === region.id)) {
      return;
    }
    setSelectedRegions([...selectedRegions, region]);
    onChange([...selectedRegionsIds, region.id]);
  };
  const removeRegion = (region: RegionItem) => {
    const removedIndex = selectedRegionsIds.indexOf(region.id);
    selectedRegionsIds.splice(removedIndex, 1);
    selectedRegions.splice(removedIndex, 1);
    setSelectedRegions([...selectedRegions]);
    onChange([...selectedRegionsIds]);
  };
  const inputChange = useRef(
    debounce(async (value) => {
      const result = await getRegions({
        variables: {
          searchTerm: value,
        },
      });
      setRegions(result.data?.regions || []);
      setLoading(result.loading);
    }, 500),
  ).current;
  return (
    <div className={className}>
      <Combobox
        id="regionsCombobox"
        placeholder="Select your desired regions"
        inputClassName="focus:ring-primary focus:border-primary sm:text-sm border-support-line-darker rounded cursor-pointer"
        onInputChange={inputChange}
        items={regions || []}
        label="Regions"
        loadingItems={loading}
        onChange={(selectedItem) => selectedItem && addRegion(selectedItem)}
        onReset={() => null}
        openMenuOnFocus
        renderItemComponent={RegionResult}
        renderLoadingComponent={() => <p className="py-2 px-4">loading...</p>}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No regions found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
      <div className="flex flex-wrap">
        {selectedRegions.map((region) => {
          return (
            <Pill
              key={region.id}
              onClose={() => removeRegion(region)}
              className="mr-2 mb-2"
              toolTipText={region.id}
              data-cy={`pill-${region.id}`}
            >
              {region.name}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default RegionInput;
