/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClientTenderStatus {
  BLOCKED = "BLOCKED",
  FAVORITE = "FAVORITE",
  LESS_PREFERRED = "LESS_PREFERRED",
}

export enum CountryFilter {
  CA = "CA",
  US = "US",
}

export enum DeprecatedParkingEnum {
  DIFFICULT = "DIFFICULT",
  LOT_FREE = "LOT_FREE",
  LOT_NEARBY = "LOT_NEARBY",
  LOT_TO_BE_CONFIRMED = "LOT_TO_BE_CONFIRMED",
  NO_PARKING = "NO_PARKING",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  STREET_AMPLE = "STREET_AMPLE",
  STREET_FAR = "STREET_FAR",
  STREET_LIMITED = "STREET_LIMITED",
}

export enum FilterOperator {
  all = "all",
  any = "any",
  between = "between",
  equals = "equals",
  greater = "greater",
  less = "less",
  like = "like",
  none = "none",
  not = "not",
  notlike = "notlike",
}

export enum InvoiceStatus {
  CANCELLED = "CANCELLED",
  SENT = "SENT",
}

export enum InvoicingStatus {
  INVOICED = "INVOICED",
  LIBERATED = "LIBERATED",
  PENDING = "PENDING",
}

export enum NetDOption {
  NET_10 = "NET_10",
  NET_15 = "NET_15",
  NET_30 = "NET_30",
  NET_45 = "NET_45",
  NET_60 = "NET_60",
  NET_7 = "NET_7",
}

export enum NotificationProcessor {
  EMAIL = "EMAIL",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  SMS = "SMS",
}

export enum OrderByDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ParkingEnum {
  LOT_FREE = "LOT_FREE",
  LOT_NEARBY = "LOT_NEARBY",
  LOT_TO_BE_CONFIRMED = "LOT_TO_BE_CONFIRMED",
  NO_PARKING = "NO_PARKING",
  STREET_AMPLE = "STREET_AMPLE",
  STREET_FAR = "STREET_FAR",
  STREET_LIMITED = "STREET_LIMITED",
}

export enum PaymentStatus {
  ONHOLD = "ONHOLD",
  PAID = "PAID",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  WONTPAY = "WONTPAY",
}

export enum PaymentsProviderStatus {
  ERROR = "ERROR",
  PROVIDER_ERROR = "PROVIDER_ERROR",
  QUEUED = "QUEUED",
  REQUESTED = "REQUESTED",
  SENT = "SENT",
}

export enum PositionApplicationStatus {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  IN_REVIEW = "IN_REVIEW",
  MANUALLY_ADDED = "MANUALLY_ADDED",
  MANUALLY_REMOVED = "MANUALLY_REMOVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  WITHDRAWN = "WITHDRAWN",
}

export enum PricingSource {
  CUSTOM = "CUSTOM",
  ORGANIZATION = "ORGANIZATION",
  POSITION = "POSITION",
  REGION = "REGION",
}

export enum ShiftNoteType {
  ADMIN = "ADMIN",
  FLEXPOOL = "FLEXPOOL",
  LOCATION_CONTACT = "LOCATION_CONTACT",
}

export enum ShiftOverStaffedUnit {
  PERCENT = "PERCENT",
  RATIO = "RATIO",
  UNIT = "UNIT",
}

export enum ShiftRequestStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
}

export enum ShiftStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
}

export enum ShiftWorkerClassification {
  NONE = "NONE",
  WC_1099 = "WC_1099",
  WC_W2 = "WC_W2",
}

export enum StaffingStatus {
  APPROVED = "APPROVED",
  CALLOUT = "CALLOUT",
  CANCELLED = "CANCELLED",
  CLOCKEDIN = "CLOCKEDIN",
  CLOCKEDOUT = "CLOCKEDOUT",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  FAULT = "FAULT",
  ONCALL = "ONCALL",
  OPEN = "OPEN",
  PENDING = "PENDING",
  REMOVED = "REMOVED",
  STAFFED = "STAFFED",
  UNCONFIRMED = "UNCONFIRMED",
  WITHDRAWN = "WITHDRAWN",
}

export enum TenderCertificationStatus {
  CONFIRMED = "CONFIRMED",
  EXPIRED = "EXPIRED",
  INVALID = "INVALID",
  PARTIALLY_INVALID = "PARTIALLY_INVALID",
  PENDING = "PENDING",
}

export enum TenderPaymentStatus {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  SENT = "SENT",
}

export enum TenderPaymentType {
  BONUS = "BONUS",
  OVERTIME = "OVERTIME",
  STANDARD = "STANDARD",
}

export enum TenderRosterTypeEnum {
  PLACEHOLDER = "PLACEHOLDER",
  TENDER = "TENDER",
}

export enum TenderStatus {
  ACTIVE = "ACTIVE",
  ONBOARDING = "ONBOARDING",
  PROBATION = "PROBATION",
  PROFILE_COMPLETED = "PROFILE_COMPLETED",
}

export enum TenderTier {
  BRONZE = "BRONZE",
  DISMISSAL = "DISMISSAL",
  GOLD = "GOLD",
  NEW = "NEW",
  SILVER = "SILVER",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export enum TipType {
  ALLOW_JAR = "ALLOW_JAR",
  INCLUDE_TIP = "INCLUDE_TIP",
  NO_TIP = "NO_TIP",
  ON_SITE = "ON_SITE",
}

export enum VenueType {
  COMMERCIAL = "COMMERCIAL",
  RESIDENTIAL = "RESIDENTIAL",
}

export interface AddressInput {
  streetName?: string | null;
  streetNumber?: string | null;
  interiorNumber?: string | null;
  neighborhood?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  lat: number;
  lng: number;
}

export interface AdjustPaidStaffingInput {
  id: string;
  actualStartDateTime?: any | null;
  actualEndDateTime?: any | null;
  actualUnpaidBreakMinutes?: number | null;
  cancelRelatedInvoice?: boolean | null;
}

export interface AdjustmentTenderPaymentFilter {
  equals: boolean;
}

export interface AdminPreferencesInput {
  showDayOfStats?: boolean | null;
}

export interface AdminTenderNotificationFilters {
  tenderIds?: TenderIdsFilter | null;
}

export interface AdminTenderPhotoIdInput {
  front?: string | null;
}

export interface AssignAdminToClientInput {
  id?: string | null;
  adminId: string;
  clientId: string;
  regionId?: string | null;
}

export interface BulkTenderPayoutFilters {
  tenderPayoutIds: TenderPayoutIdsFilter;
}

export interface ChangeTenderStatusAsAdminInput {
  tenderId: string;
  clientId: string;
}

export interface ClientIdsFilter {
  contains: string[];
}

export interface ClientTenderPaymentFilter {
  contains: string[];
}

export interface ContactEditInput {
  name: string;
  phoneNumber?: string | null;
  instructions?: string | null;
  id?: string | null;
}

export interface CoordsInput {
  lat: number;
  lng: number;
}

export interface CountryTenderPaymentFilter {
  contains: CountryFilter[];
}

export interface CreateInvoiceInput {
  customId: string;
  adjustment?: number | null;
  adjustmentDescription?: string | null;
  clientName?: string | null;
  streetAddress?: string | null;
  state?: string | null;
  zipCode?: string | null;
  staffingIds?: string[] | null;
  notStaffingIds?: string[] | null;
  staffingFilters?: Filter[] | null;
  useAlternativeAddress?: boolean | null;
  netDOption: NetDOption;
}

export interface CreatePlaceholderTenderInput {
  rosterId: string;
  shiftIds: string[];
  tenderName: string;
}

export interface CreateRosterInput {
  jobIds: string[];
  clientId: string;
}

export interface CreateShiftNoteInput {
  shiftId: string;
  note: string;
}

export interface CreateShiftRequestsInput {
  shiftId: string;
  tenderIds: string[];
}

export interface CreateTenderPayoutInput {
  tenderId: string;
  amountInDollars?: number | null;
  amount?: number | null;
  chargeToClient?: boolean | null;
  clientAmountInDollars?: number | null;
  clientAmount?: number | null;
  shiftId?: string | null;
  description?: string | null;
}

export interface CreatedAtTenderPaymentFilter {
  greaterThanOrEqual: any;
  lessThanOrEqual: any;
}

export interface CustomPositionIdsFilter {
  contains: string[];
}

export interface DefaultPositionIdsFilter {
  contains: string[];
}

export interface DistanceFilter {
  lat: number;
  lng: number;
  searchRadius: number;
}

export interface EditJobVenueInput {
  venue: VenueInput;
  mealProvided: boolean;
  useBackDoor: boolean;
  locationContact?: ContactEditInput | null;
}

export interface EvereeTenderPaymentFilter {
  equals: boolean;
}

export interface Filter {
  property: string;
  operator: FilterOperator;
  values: string[];
  nested?: Filter[] | null;
}

export interface GetAdminsQueryOptions {
  searchTerm?: string | null;
  role?: string | null;
  excludeMe?: boolean | null;
}

export interface GetAssignmentsFilters {
  searchTerm?: string | null;
  adminId?: string | null;
  unassigned?: boolean | null;
}

export interface GetClientsQueryOptions {
  searchTerm?: string | null;
  staffingAdminId?: string | null;
  supportAdminId?: string | null;
}

export interface InvoiceDatesFilter {
  greaterThanOrEqual: any;
  lessThanOrEqual: any;
}

export interface InvoiceFilters {
  invoiceDates?: InvoiceDatesFilter | null;
  customId?: string | null;
  clientName?: string | null;
}

export interface JobBasicInfoInput {
  name?: string | null;
  description?: string | null;
}

export interface JobDatesFilter {
  greaterThanOrEqual?: any | null;
  lessThanOrEqual?: any | null;
  endsBefore?: any | null;
  endsAfter?: any | null;
}

export interface JobIdsFilter {
  contains: string[];
}

export interface JobPricingInput {
  jobId: string;
}

export interface JobTenderPaymentFilter {
  contains: string[];
}

export interface JobsFilters {
  jobDates?: JobDatesFilter | null;
  clientIds?: ClientIdsFilter | null;
  superRegionIds?: StringComparator | null;
  regionIds?: StringComparator | null;
  staffingStatus?: StringComparator | null;
  staffedShiftPaymentStatus?: PaymentStatusFilter | null;
  published?: boolean | null;
  isFlexpoolOrder?: boolean | null;
  cancelled?: boolean | null;
  hasAttachments?: boolean | null;
  hiddenFromClient?: boolean | null;
}

export interface JobsQueryOptions {
  startDate?: any | null;
  endDate?: any | null;
  regionId?: string | null;
  searchTerm?: string | null;
}

export interface ListOptions {
  searchTerm?: string | null;
  orderByField?: string | null;
  orderByDirection?: OrderByDirectionEnum | null;
}

export interface OptionalSearchTerm {
  searchTerm?: string | null;
}

export interface OrganizationIdsFilter {
  contains: string[];
}

export interface PaginationOptions {
  limit: number;
  page: number;
}

export interface PayableStaffingClientFilter {
  searchTerm: string;
}

export interface PayableStaffingFilters {
  shift?: PayableStaffingShiftFilter | null;
  job?: PayableStaffingJobFilter | null;
  tender?: PayableStaffingTenderFilter | null;
  client?: PayableStaffingClientFilter | null;
  staffingIds?: StaffingTenderPaymentFilter | null;
  shiftIds?: ShiftTenderPaymentFilter | null;
  jobIds?: JobTenderPaymentFilter | null;
  clientIds?: ClientTenderPaymentFilter | null;
  tenderIds?: TenderTenderPaymentFilter | null;
  country?: CountryFilter | null;
  workerClassification?: ShiftWorkerClassification[] | null;
  paymentsProviderStatus?: PaymentsProviderStatus[] | null;
  excludeWithInProgressPayments?: boolean | null;
}

export interface PayableStaffingJobFilter {
  searchTerm: string;
}

export interface PayableStaffingQueryOptions {
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  filters?: PayableStaffingFilters | null;
}

export interface PayableStaffingShiftDateFilter {
  greaterThanOrEqual: any;
  lessThanOrEqual: any;
}

export interface PayableStaffingShiftFilter {
  dates?: PayableStaffingShiftDateFilter | null;
  searchTerm?: string | null;
}

export interface PayableStaffingTenderFilter {
  searchTerm: string;
}

export interface PaymentStatusFilter {
  contains: PaymentStatus[];
}

export interface PaymentsProviderStatusFilter {
  contains?: PaymentsProviderStatus[] | null;
  notContains?: PaymentsProviderStatus[] | null;
  empty?: boolean | null;
}

export interface PendingApplicantsFilter {
  equals: boolean;
}

export interface RegionIdsFilter {
  contains: string[];
}

export interface RegionInput {
  name: string;
  polygon: CoordsInput[];
}

export interface RegionUpdateInput {
  id: string;
  name?: string | null;
  polygon?: CoordsInput[] | null;
}

export interface RequestTenderPayoutsDataDumpOptions {
  isEvereeExport?: boolean | null;
}

export interface ShiftApplicantStatusFilter {
  contains: StaffingStatus[];
}

export interface ShiftDatesFilter {
  greaterThanOrEqual: any;
  lessThanOrEqual: any;
  endsBefore?: any | null;
  endsAfter?: any | null;
}

export interface ShiftFilters {
  shiftDates: ShiftDatesFilter;
  staffingStatus?: StringComparator | null;
  pendingApplicants?: PendingApplicantsFilter | null;
  shiftVisible?: ShiftVisiblityFilter | null;
  clientIds?: ClientIdsFilter | null;
  organizationIds?: OrganizationIdsFilter | null;
  jobIds?: JobIdsFilter | null;
  shiftIds?: ShiftIdsFilter | null;
  defaultPositionIds?: DefaultPositionIdsFilter | null;
  customPositionIds?: CustomPositionIdsFilter | null;
  tagIds?: TagIdsFilter | null;
  distance?: DistanceFilter | null;
  superRegionIds?: SuperRegionIdsFilter | null;
  regionIds?: RegionIdsFilter | null;
  shiftApplicantStatus?: ShiftApplicantStatusFilter | null;
  isFlexpoolShift?: boolean | null;
  cancelled?: boolean | null;
  hasTendersOutOfTime?: boolean | null;
  isJobUnpublished?: boolean | null;
  showOnlyMyClientStaffings?: boolean | null;
}

export interface ShiftIdsFilter {
  contains: string[];
}

export interface ShiftRequestFilters {
  status?: ShiftRequestStatus[] | null;
  shiftId?: string | null;
  tenderId?: string | null;
}

export interface ShiftTenderPaymentFilter {
  contains: string[];
}

export interface ShiftVisiblityFilter {
  equals: boolean;
}

export interface StaffingFilters {
  status: StringComparator;
  tenderId?: TenderIdFilter | null;
}

export interface StaffingTenderPaymentFilter {
  contains: string[];
}

export interface StatusTenderPaymentFilter {
  contains: TenderPaymentStatus[];
}

export interface StringComparator {
  contains: string[];
}

export interface SuperRegionIdsFilter {
  contains: string[];
}

export interface SuperRegionInput {
  name: string;
  regionsIds: string[];
}

export interface SuperRegionUpdateInput {
  id: string;
  name?: string | null;
  regionsIds: string[];
}

export interface TagIdsFilter {
  contains: string[];
}

export interface TenderAssessmentFilters {
  completed?: boolean | null;
  processed?: boolean | null;
  searchTerm?: string | null;
  state?: string | null;
  city?: string | null;
  createdFrom?: any | null;
  createdTo?: any | null;
  interviewer?: string | null;
  position?: string | null;
}

export interface TenderCertificationFilter {
  tenderIds?: string[] | null;
  status?: TenderCertificationStatus[] | null;
  searchTerm?: string | null;
}

export interface TenderIdFilter {
  contains: string[];
}

export interface TenderIdsFilter {
  contains: string[];
}

export interface TenderPaymentFilters {
  staffingIds?: StaffingTenderPaymentFilter | null;
  shiftIds?: ShiftTenderPaymentFilter | null;
  jobIds?: JobTenderPaymentFilter | null;
  clientIds?: ClientTenderPaymentFilter | null;
  tenderIds?: TenderTenderPaymentFilter | null;
  type?: TypeTenderPaymentFilter | null;
  status?: StatusTenderPaymentFilter | null;
  createDateTime?: CreatedAtTenderPaymentFilter | null;
  adjustment?: AdjustmentTenderPaymentFilter | null;
  country?: CountryTenderPaymentFilter | null;
  everee?: EvereeTenderPaymentFilter | null;
  paymentsProviderStatus?: PaymentsProviderStatusFilter | null;
}

export interface TenderPayoutIdsFilter {
  contains?: string[] | null;
  notContains?: string[] | null;
}

export interface TenderPositionsInput {
  tenderId: string;
  positionIds: string[];
}

export interface TenderRosterFilters {
  tenderRosterType?: TenderRosterTypeFilter | null;
}

export interface TenderRosterTypeFilter {
  equals: TenderRosterTypeEnum;
}

export interface TenderTenderPaymentFilter {
  contains: string[];
}

export interface TenderUpdateInput {
  id: string;
  tier?: TenderTier | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  avatarURL?: string | null;
  phoneNumber?: any | null;
  address?: AddressInput | null;
  frontId?: string | null;
}

export interface TypeTenderPaymentFilter {
  contains: TenderPaymentType[];
}

export interface UpdateJobInstructionInput {
  id: string;
  attire: string;
  blackApron: boolean;
  blackNonSlipShoes: boolean;
  maskRequired: boolean;
  barKit?: boolean | null;
  knifeKit?: boolean | null;
}

export interface UpdateShiftInput {
  id: string;
  startDateTime?: any | null;
  endDateTime?: any | null;
  quantity?: number | null;
  pricingSource?: PricingSource | null;
  rate?: number | null;
  margin?: number | null;
  tipType?: TipType | null;
  tipAmount?: number | null;
  unpaidBreakMinutes?: number | null;
  overStaffedUnit?: ShiftOverStaffedUnit | null;
  overStaffedNumber?: number | null;
}

export interface VenueInput {
  id?: string | null;
  name?: string | null;
  address: AddressInput;
  type: VenueType;
  parkingType: ParkingEnum;
  parkingInstructions?: string | null;
  otherInstructions?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
