import gql from 'graphql-tag';
import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Regions } from './__generated__/Regions';

const GET_REGIONS_V2 = gql`
  query RegionsV2($searchTerm: String) {
    regions(searchTerm: $searchTerm) {
      id
      name
      superRegion {
        id
        name
      }
      updatedAt
      polygon {
        lat
        lng
      }
    }
  }
`;

export const useRegionsV2Query = (options?: QueryHookOptions<Regions>) =>
  useLazyQuery<Regions>(GET_REGIONS_V2, options);

export default GET_REGIONS_V2;
